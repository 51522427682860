import React from 'react';
import {Grid} from '@mui/material';
import {ErrorMessage} from '~/components/Common/IconMessage/IconMessage';
import {FIELD_VALUES, SUBDOMAIN_RESULT_MESSAGE} from '~/constants';
import {SubDomainStatus} from '~/interfaces/client';
import {SimpleGridDisplay} from '~/components/Common/GridDisplay/SimpleGridDisplay';
import {ERROR_COPY} from '~/constants';
import {NoData} from '../CompletedSubDomain';
import {objNoResults} from '~/utils/contentUtils';
import {ErrorSubDomainText} from '../ErrorSubDomain';
import {isMatch} from 'date-fns';

export interface INominationStatus {
    Data: {
        NominationStatusTag: string;
        NominationDate: string;
    };
    ErrorMessage: string;
    Status: string;
}

export interface INominationStatusDetails {
    details: INominationStatus;
}

export const NominationStatusDetails: React.FC<INominationStatusDetails> = ({details}) => {
    //Flow: Error
    const subdomainNominationStatusError: boolean =
        (!details.Data && !details.ErrorMessage) || details.Status != SubDomainStatus.COMPLETED;

    if (subdomainNominationStatusError)
        return <ErrorMessage message={ErrorSubDomainText} style={{display: 'flex', alignItems: 'center'}} />;

    //Flow: No Result
    const noResults = details?.Status === SubDomainStatus.COMPLETED && objNoResults(details.Data);
    if (noResults) {
        return <NoData />;
    }

    //Flow: Success
    const nominationStatusTagValue = details.ErrorMessage
        ? `${SUBDOMAIN_RESULT_MESSAGE.EMPTY} (${details.ErrorMessage})`
        : details.Data.NominationStatusTag;
    const nominationDateValue = details.ErrorMessage
        ? `${SUBDOMAIN_RESULT_MESSAGE.EMPTY} (${details.ErrorMessage})`
        : details.Data.NominationDate;

    const evaluateNominationStatusTagValue = (value: string) => {
        if (value == 'Y') {
            return 'Yes';
        } else if (value == FIELD_VALUES.NO_ACCESS) {
            return FIELD_VALUES.NO_ACCESS;
        } else if (value == 'N') {
            return 'No';
        } else if (value == FIELD_VALUES.NO_RESULTS) {
            return FIELD_VALUES.NO_RESULTS;
        } else {
            return ERROR_COPY;
        }
    };

    const evaluateNominationDateValue = (value: string) => {
        return isMatch(value, 'yyyy-MM-dd') || value == FIELD_VALUES.NO_RESULTS || value == FIELD_VALUES.NO_ACCESS
            ? value
            : ERROR_COPY;
    };

    const fields = [
        {
            label: 'Has Nomination',
            value: evaluateNominationStatusTagValue(nominationStatusTagValue),
        },
        {
            label: 'Nomination Date',
            value: evaluateNominationDateValue(nominationDateValue),
        },
    ];

    return (
        <Grid container spacing={3}>
            <SimpleGridDisplay fields={fields} />
        </Grid>
    );
};
